import { Analytics } from "./analytics.js";

export let MailingList = {
  DOM: {
    submitBtn: () => $("#mc_embed_signup input[type=submit]"),
    inlineError: () => $("#mc_embed_signup div.mce_inline_error"),
    errorResponse: () => $("#mce-error-response"),
    successResponse: () => $("#mce-success-response"),
    emailInput: () => $("input#mce-EMAIL"),
  },

  handle: () => {
    MailingList.DOM.submitBtn().click((e) => {
      MailingList.DOM.inlineError().hide()
      MailingList.DOM.errorResponse().hide()
      MailingList.DOM.successResponse().hide()
      var email = MailingList.DOM.emailInput().val()

      setTimeout(function() {
        var res = MailingList.DOM.successResponse().html().trim()
        if(res == "Thank you for subscribing!") {
          Analytics.subscribedToNewsletter(email)
        }
      }, 3000)

      return true;
    })
  }
}
