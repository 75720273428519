import Cookies from 'js-cookie'

export let Alert = {
  DOM: {
    alert: () => $('#evermade-alert'),
    msg: () => Alert.DOM.alert().find("p"),
    closeBtn: () => Alert.DOM.alert().find("button")
  },

  collapse: null,
  COOKIE_NAME: "evermade-alert-message-dismissed",

  handle: () => {
    if(Alert.message() != "" && !Alert.alreadyDismissedThisMessage()) {
      setTimeout(() => {
        Alert.DOM.closeBtn().on("click touchstart", Alert.dismissed)
        Alert.collapse = new bootstrap.Collapse(Alert.DOM.alert(), {toggle: false})
        Alert.collapse.show()
      }, 1000)
    }
  },

  message: () => {
    return Alert.DOM.msg().html().trim()
  },

  dismissed: () => {
    Alert.collapse.hide()
    Cookies.set(Alert.COOKIE_NAME, Alert.message(), { expires: 7 })
  },

  alreadyDismissedThisMessage: () => {
    return Cookies.get(Alert.COOKIE_NAME) == Alert.message()
  }
}
