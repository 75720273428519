export let TechnoDystopiaHints = {
  DOM: {
    colorBoxes: () => $("#hints .hint .boxes .color-box"),
    colorBoxForColor: (color) => $("#hints .hint .boxes .color-box[data-em-color='" + color + "']"),
    allColorBoxHints: (color) => $("#hints .hint .box-hints"),
    colorBoxHintsForColor: (color) => $("#hints .hint .box-hints." + color)
  },

  handle: () => {
    TechnoDystopiaHints.DOM.colorBoxes().on("click touchstart", TechnoDystopiaHints.colorBoxClicked)
    TechnoDystopiaHints.colorBoxClicked({target: TechnoDystopiaHints.DOM.colorBoxForColor("red")})
  },

  colorBoxClicked: (e) => {
    var colorBox = $(e.target)
    if(!colorBox.hasClass("color-box"))
      colorBox = colorBox.parent()

    var color = colorBox.attr("data-em-color")

    TechnoDystopiaHints.DOM.colorBoxes().removeClass("selected")
    colorBox.addClass("selected")

    TechnoDystopiaHints.DOM.allColorBoxHints().hide()
    TechnoDystopiaHints.DOM.colorBoxHintsForColor(color).show()
  }
}
