import Cookies from 'js-cookie'
import { TechnoDystopiaHints } from "./techno-dystopia-hints.js";
import { Nav } from "./nav.js";

export let JigsawPuzzleHints = {
  DOM: {
    section: () => $("section.jigsaw-puzzle-hints"),
    zipImageRow: () => JigsawPuzzleHints.DOM.section().find(".zip-image"),
    slides: () => JigsawPuzzleHints.DOM.section().find(".slide"),
    slide: (id) => JigsawPuzzleHints.DOM.section().find(".slide#" + id),
    introSlide: () => JigsawPuzzleHints.DOM.slide("intro"),
    checkAnswerSlide: () => JigsawPuzzleHints.DOM.slide("answer"),
    answerTextBox: () => JigsawPuzzleHints.DOM.checkAnswerSlide().find("input"),
    incorrectGenericSlide: () => JigsawPuzzleHints.DOM.slide("incorrect-generic"),
    responseSlide: (forAnswer) => JigsawPuzzleHints.DOM.section().find(".slide.response[data-em-for='" + forAnswer + "']"),
    slideNavButtons: () => JigsawPuzzleHints.DOM.slides().find("button[data-em-to]"),
    checkAnswerButton: () => JigsawPuzzleHints.DOM.checkAnswerSlide().find("#check-answer-button"),
    hints: () => JigsawPuzzleHints.DOM.slide("hints").find(".hint"),
    hint: (num) => JigsawPuzzleHints.DOM.slide("hints").find(".hint[data-em-hint-name=" + num + "]"),
    resetHintsButton: () => JigsawPuzzleHints.DOM.slide("hints").find("#reset-hints"),
    zoomingSolutionImageWrappers: () => JigsawPuzzleHints.DOM.section().find(".slide.solution .solution-image-wrapper.zoom"),
  },

  handle: () => {
    JigsawPuzzleHints.DOM.slides().hide()
    JigsawPuzzleHints.DOM.slide("intro").show()
    JigsawPuzzleHints.DOM.slideNavButtons().on("click touchstart", JigsawPuzzleHints.slideNavButtonClicked)
    JigsawPuzzleHints.DOM.checkAnswerButton().on("click touchstart", JigsawPuzzleHints.checkAnswer)
    JigsawPuzzleHints.DOM.answerTextBox().on("keypress", JigsawPuzzleHints.answerTextBoxKeyPressed)
    JigsawPuzzleHints.setUpHints()
    JigsawPuzzleHints.DOM.zoomingSolutionImageWrappers().mousemove(JigsawPuzzleHints.solutionImageHovered)
    JigsawPuzzleHints.DOM.zoomingSolutionImageWrappers().mouseout(JigsawPuzzleHints.solutionImageUnhovered)

    // handle specific products
    TechnoDystopiaHints.handle()
  },

  setUpHints: () => {
    $.each(JigsawPuzzleHints.hintsVisited(), (i, hNum) => {
      if(hNum != '')
        JigsawPuzzleHints.DOM.hint(hNum).removeClass("concealed")
    })

    JigsawPuzzleHints.DOM.hints().on("click touchstart", JigsawPuzzleHints.hintClicked)

    JigsawPuzzleHints.DOM.resetHintsButton().on("click touchstart", JigsawPuzzleHints.resetHintsVisited)
  },

  hintClicked: (e) => {
    var hint = $(e.target)
    while(!hint.hasClass("hint")) hint = hint.parent()
    var isConcealed = hint.hasClass("concealed")
    var isLocked = hint.prev(".concealed").length > 0

    if(isConcealed && !isLocked) {
      hint.removeClass("concealed")
      JigsawPuzzleHints.setHintVisited(hint)
      var nextHint = hint.next()
    }
  },

  hintsVisitedCookieName: () => {
    var productSlug = JigsawPuzzleHints.DOM.section().attr("data-em-product")
    return productSlug + "-hints-visited"
  },

  setHintVisited: (hint) => {
    var cName = JigsawPuzzleHints.hintsVisitedCookieName()
    var hintName = $(hint).attr("data-em-hint-name")
    var visited = JigsawPuzzleHints.hintsVisited()
    visited.push(hintName)
    Cookies.set(cName, visited.join(","), { expires: 365 })
  },

  resetHintsVisited: () => {
    var cName = JigsawPuzzleHints.hintsVisitedCookieName()
    Cookies.set(cName, "", { expires: 365 })
    JigsawPuzzleHints.DOM.hints().addClass("concealed")
    Nav.scrollTo(JigsawPuzzleHints.DOM.section())
  },

  hintsVisited: () => {
    var cName = JigsawPuzzleHints.hintsVisitedCookieName()
    return (Cookies.get(cName) || "").split(",")
  },

  slideNavButtonClicked: (e) => {
    var toSlideId = $(e.target).attr("data-em-to")
    var toSlide = JigsawPuzzleHints.DOM.slide(toSlideId)
    JigsawPuzzleHints.navToSlide(toSlide)
  },

  navToSlide: (toSlide) => {
    JigsawPuzzleHints.DOM.slides().hide()
    toSlide.show()

    if(toSlide.hasClass("solution")) {
      JigsawPuzzleHints.DOM.zipImageRow().hide()
      JigsawPuzzleHints.DOM.section().addClass("top-align")
      Nav.scrollTo(JigsawPuzzleHints.DOM.section())
    }
  },

  answerTextBoxKeyPressed: (e) => {
    if(e.which == 13)
      JigsawPuzzleHints.checkAnswer()
  },

  checkAnswer: () => {
    var answer = JigsawPuzzleHints.DOM.answerTextBox().val().trim().toLowerCase()
    answer = answer.replace(/[^a-z ]/g,"")
    answer = answer.replace(/[ ]+/g," ")
    var responseSlide = JigsawPuzzleHints.DOM.responseSlide(answer)
    if(responseSlide.length == 0) {
      JigsawPuzzleHints.navToSlide(JigsawPuzzleHints.DOM.incorrectGenericSlide())
    } else {
      JigsawPuzzleHints.navToSlide(responseSlide)
    }
  },

  solutionImageHovered: (e) => {
    var solutionImageWrapper = $(e.target)
    if(!solutionImageWrapper.hasClass("solution-image-wrapper"))
      solutionImageWrapper = solutionImageWrapper.parent()
    var zoomingImage = solutionImageWrapper.find("img").last()

    var bWidth = 10 // border width set in css

    var iWidth = solutionImageWrapper.width() - 2 * bWidth
    var iHeight = solutionImageWrapper.height() - 2 * bWidth
    var iX = e.pageX - solutionImageWrapper.offset().left - bWidth;
    var iY = e.pageY - solutionImageWrapper.offset().top - bWidth;
    iX = Math.min(Math.max(iX, 0), iWidth)
    iY = Math.min(Math.max(iY, 0), iHeight)

    var zoom = 3 // 2 corresponds to the 200% zoom set in css
    var zX = zoom * iX
    var zY = zoom * iY
    var cX = iWidth / 2
    var cY = iHeight / 2
    var zShiftX = cX - zX
    var zShiftY = cY - zY
    zShiftX = Math.min(Math.max(zShiftX, -1 * (zoom - 1) * iWidth), 0)
    zShiftY = Math.min(Math.max(zShiftY, -1 * (zoom - 1) * iHeight), 0)

    zoomingImage.css({top: zShiftY, left: zShiftX, opacity: 1})
  },

  solutionImageUnhovered: (e) => {
    var solutionImageWrapper = $(e.target)
    if(!solutionImageWrapper.hasClass(".solution-image-wrapper"))
      solutionImageWrapper = solutionImageWrapper.parent()
    var zoomingImage = solutionImageWrapper.find("img").last()
    zoomingImage.css({opacity: 0.01})
  }
}
