import { Utils } from "./utils.js";

export let ProductBullets = {
  DOM: {
    section: () => $("section.product-bullets"),
    titles: () => ProductBullets.DOM.section().find(".title")
  },

  handle: () => {
    if(ProductBullets.DOM.section().length == 0) return
    $(window).resize(ProductBullets.doTitleResize)
    ProductBullets.doTitleResize()
  },

  doTitleResize: () => {
    var titles = ProductBullets.DOM.titles()
    titles.css({width: "100%"});

    var groupingsOf = 1;
    if(Utils.viewportIsSM() || Utils.viewportIsMD())
      groupingsOf = 2
    else
      groupingsOf = 3

    var numGroupings = Math.ceil(titles.length / groupingsOf)
    for(var group = 0; group < numGroupings; group++) {
      var titlesInGrouping = titles.slice(group * groupingsOf, group * groupingsOf + groupingsOf)
      if(titlesInGrouping <= 1) continue;
      var maxHeight = Math.max(...$.map(titlesInGrouping, (title) => $(title).outerHeight()))
      $.each(titlesInGrouping, (i, title) => {
        for(var w = 10; w <= 100; w += 10) {
          $(title).css({width: w + "%"})
          if($(title).outerHeight() <= maxHeight) break;
        }
      })
    }
  }
}
