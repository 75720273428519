import Cookies from 'js-cookie'

export let Analytics = {
  DOM: {
    gdprNotice: () => $("#gdpr-notice"),
    gdprNoticeOKButton: () => Analytics.DOM.gdprNotice().find("button.ok"),
    consentPreferencesText: () => $("#consent-preferences"),
    consentPreferencesControls: () => $(".consent-preferences-controls"),
    statisticsConsentToggle: () => $("#statistics-consent-toggle"),
    marketingConsentToggle: () => $("#marketing-consent-toggle"),
    contactFormAlert: () => $("section.contact-form#contact p.contact-alert")
  },

  Consent: {
    GRANTED: "granted",
    DENIED: "denied"
  },

  HAS_MADE_CONSENT_CHOICE_COOKIE: "evermade-consent-choice-made",
  ADS_CONSENT_CHOICE_COOKIE: "evermade-ads-consent",
  ANALYTICS_CONSENT_CHOICE_COOKIE: "evermade-analytics-consent",

  handle: () => {
    Analytics.handleGDPR()

    if(Analytics.DOM.contactFormAlert().hasClass("notice")) {
      Analytics.contactFormSubmitted()
    }

    Analytics.pageViewed()
  },

  handleGDPR: () => {
    // Analytics.resetConsentCookies()

    if(Analytics.isSubjectToGDPR()) {
      Analytics.DOM.consentPreferencesText().show()
      Analytics.DOM.consentPreferencesControls().show()
      Analytics.DOM.marketingConsentToggle().change(Analytics.marketingConsentToggleChanged)
      Analytics.DOM.statisticsConsentToggle().change(Analytics.statisticsConsentToggleChanged)

      if(!Analytics.hasMadeConsentChoice()) {
        Analytics.showGDPRNotice()
        Analytics.DOM.gdprNoticeOKButton().on("click touchstart", Analytics.madeConsentChoice)
      } else {
        // assumes that permissions are granted by default
        // if changed to denied by default, this needs to be updated
        if(Analytics.hasDeniedAds()) {
          Analytics.denyAdsConsent()
          Analytics.DOM.marketingConsentToggle().prop('checked', false)
        }
        if(Analytics.hasDeniedAnalytics()) {
          Analytics.denyAnalyticsConsent()
          Analytics.DOM.statisticsConsentToggle().prop('checked', false)
        }
      }
    }
  },

  showGDPRNotice: () => {
    Analytics.DOM.gdprNotice().collapse("show")
  },

  resetConsentCookies: () => {
    Cookies.remove(Analytics.HAS_MADE_CONSENT_CHOICE_COOKIE)
    Cookies.remove(Analytics.ADS_CONSENT_CHOICE_COOKIE)
    Cookies.remove(Analytics.ANALYTICS_CONSENT_CHOICE_COOKIE)
  },

  hasMadeConsentChoice: () => {
    return Cookies.get(Analytics.HAS_MADE_CONSENT_CHOICE_COOKIE) == "true"
  },

  madeConsentChoice: () => {
    Cookies.set(Analytics.HAS_MADE_CONSENT_CHOICE_COOKIE, "true", { expires: 365 })
    Analytics.DOM.gdprNotice().collapse("hide")
  },

  // if no choice has been explicity set, assumed to be granted
  hasDeniedAds: () => {
    return Cookies.get(Analytics.ADS_CONSENT_CHOICE_COOKIE) == Analytics.Consent.DENIED
  },

  // if no choice has been explicity set, assumed to be granted
  hasDeniedAnalytics: () => {
    return Cookies.get(Analytics.ANALYTICS_CONSENT_CHOICE_COOKIE) == Analytics.Consent.DENIED
  },

  isSubjectToGDPR: () => {
    var tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    switch (tz) {
      case 'Europe/Vienna':
        return true;
      case 'Europe/Brussels':
        return true;
      case 'Europe/Sofia':
        return true;
      case 'Europe/Zagreb':
        return true;
      case 'Asia/Famagusta':
        return true;
      case 'Asia/Nicosia':
        return true;
      case 'Europe/Prague':
        return true;
      case 'Europe/Copenhagen':
        return true;
      case 'Europe/Tallinn':
        return true;
      case 'Europe/Helsinki':
        return true;
      case 'Europe/Paris':
        return true;
      case 'Europe/Berlin':
        return true;
      case 'Europe/Busingen':
        return true;
      case 'Europe/Athens':
        return true;
      case 'Europe/Budapest':
        return true;
      case 'Europe/Dublin':
        return true;
      case 'Europe/Rome':
        return true;
      case 'Europe/Riga':
        return true;
      case 'Europe/Vilnius':
        return true;
      case 'Europe/Luxembourg':
        return true;
      case 'Europe/Malta':
        return true;
      case 'Europe/Amsterdam':
        return true;
      case 'Europe/Warsaw':
        return true;
      case 'Atlantic/Azores':
        return true;
      case 'Atlantic/Madeira':
        return true;
      case 'Europe/Lisbon':
        return true;
      case 'Europe/Bucharest':
        return true;
      case 'Europe/Bratislava':
        return true;
      case 'Europe/Ljubljana':
        return true;
      case 'Africa/Ceuta':
        return true;
      case 'Atlantic/Canary':
        return true;
      case 'Europe/Madrid':
        return true;
      case 'Europe/Stockholm':
        return true;
      default:
        return false;
    }
  },

  updateConsent: (permission, value) => {
    var opts = {}
    opts[permission] = value
    gtag('consent', 'update', opts);
    Analytics.madeConsentChoice()
  },

  updateAdsConsent: (value) => {
    Analytics.updateConsent("ad_storage", value)
    Cookies.set(Analytics.ADS_CONSENT_CHOICE_COOKIE, value, { expires: 365 })
  },

  marketingConsentToggleChanged: () => {
    var isChecked = Analytics.DOM.marketingConsentToggle().is(":checked")
    if(isChecked)
      Analytics.grantAdsConsent()
    else
      Analytics.denyAdsConsent()
  },

  grantAdsConsent: () => {
    Analytics.updateAdsConsent(Analytics.Consent.GRANTED)
  },

  denyAdsConsent: () => {
    Analytics.updateAdsConsent(Analytics.Consent.DENIED)
  },

  statisticsConsentToggleChanged: () => {
    var isChecked = Analytics.DOM.statisticsConsentToggle().is(":checked")
    if(isChecked)
      Analytics.grantAnalyticsConsent()
    else
      Analytics.denyAnalyticsConsent()
  },

  updateAnalyticsConsent: (value) => {
    Analytics.updateConsent("analytics_storage", value)
    Cookies.set(Analytics.ANALYTICS_CONSENT_CHOICE_COOKIE, value, { expires: 365 })
  },

  grantAnalyticsConsent: () => {
    Analytics.updateAnalyticsConsent(Analytics.Consent.GRANTED)
  },

  denyAnalyticsConsent: () => {
    Analytics.updateAnalyticsConsent(Analytics.Consent.DENIED)
  },

  pageViewed: () => {
    var pinterestPageVisitParams = {}
    if(window.product) {
      pinterestPageVisitParams.line_items = [{
        product_id: window.product.sku
      }]
    }
    pintrk('track', 'pagevisit', pinterestPageVisitParams);
  },

  contactFormSubmitted: () => {
    // var callback = function () {
    //   if (typeof(url) != 'undefined') {
    //     window.location = url;
    //   }
    // };

    gtag('event', 'conversion', {
        'send_to': 'AW-10905880235/pcksCOCCwr8DEKuNqtAo',
        'value': 1 // for google ads conv tracking purposes
        // 'event_callback': callback
    });

    gtag('event', 'Contact Form Submitted');
    return false;
  },

  subscribedToNewsletter: (email) => {
    // var callback = function () {
    //   if (typeof(url) != 'undefined') {
    //     window.location = url;
    //   }
    // };
    gtag('event', 'conversion', {
        'send_to': 'AW-10905880235/ffz4COLGwb8DEKuNqtAo',
        'value': 1 // for google ads conv tracking
        // 'event_callback': callback
    });
    gtag('event', 'Subscribed to Newsletter');

    pintrk('track', 'lead', {
      lead_type: 'Newsletter'
    });

    Analytics.emtrk("Subscribe", {user_email: email})

    return false;
  },

  addToCartButtonClicked: (e) => {
    // var callback = function () {
    //   if (typeof(url) != 'undefined') {
    //     window.location = url;
    //   }
    // };

    gtag('event', 'conversion', {
        'send_to': 'AW-10905880235/7DuZCM-NwL8DEKuNqtAo',
        'value': e.selectedVariant.price.amount/10, // google ads conv value not 100% of price since its not that valuable
        'currency': 'USD' // ,
        // 'event_callback': callback
    });

    gtag('event', 'Product Added to Cart', {
      'event_category' : e.selectedVariant.sku,
      'value' : e.selectedVariant.price.amount
    });

    var productCategory = "Games"
    if(e.selectedVariant.sku.match("SZB-3")) {
      productCategory = "Puzzle Books"
    } else if(e.selectedVariant.sku.match("SZB-5")) {
      productCategory = "Jigsaw Puzzles"
    } else if(e.selectedVariant.sku.match("SZB-6")) {
      productCategory = "Kids Books"
    }

    pintrk('track', 'addtocart', {
      value: e.selectedVariant.price.amount,
      currency: 'USD',
      line_items: [{
        product_id: e.selectedVariant.sku,
        product_price: e.selectedVariant.price.amount,
        product_category: productCategory
      }]
    });

    Analytics.emtrk("AddToCart", {product: {id: e.selectedVariant.sku, price: e.selectedVariant.price.amount}})


    return false;
  },

  checkoutOpened: (e) => {
    var total = 0
    $.each(e.lineItemCache, (i, lineItem) => {
      total += lineItem.quantity * lineItem.variant.price
    })
    total = parseFloat(total).toFixed(2)

    // var callback = function () {
    //   if (typeof(url) != 'undefined') {
    //     window.location = url;
    //   }
    // };
    gtag('event', 'conversion', {
        'send_to': 'AW-10905880235/FQvBCI3jjb8DEKuNqtAo',
        'value': total/10, // not full value for google ads conv purposes
        'currency': 'USD' // ,
        // 'event_callback': callback
    });
    gtag('event', 'Checkout Opened', {
      'value' : total
    });

    var emproducts = $.map(e.lineItemCache, (lineItem, i) => {
      return {
        id: lineItem.variant.sku,
        quantity: lineItem.quantity,
        price: lineItem.variant.price
      }
    })

    Analytics.emtrk("InitiateCheckout", {products: emproducts, total: total})

    return false;
  },

  emtrk: (event_name, data) => {
    data.event_name = event_name
    data.event_source_url = window.location.href
    $.post("/emtrk", data)
  }
}
