export let Utils = {
  ViewportSizes: {
    XS: "xs",
    SM: "sm",
    MD: "md",
    LG: "lg",
    XL: "xl"
  },

  VIEWPORT_RESIZE_EVENT: "viewportResize",

  viewportSize: () => {
    // https://stackoverflow.com/a/8876069
    const width = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    )
    if (width <= 576) return Utils.ViewportSizes.XS
    if (width <= 768) return Utils.ViewportSizes.SM
    if (width <= 992) return Utils.ViewportSizes.MD
    if (width <= 1200) return Utils.ViewportSizes.LG
    return Utils.ViewportSizes.XL
  },

  viewportIsXS: () => { return Utils.viewportSize() == Utils.ViewportSizes.XS },
  viewportIsSM: () => { return Utils.viewportSize() == Utils.ViewportSizes.SM },
  viewportIsMD: () => { return Utils.viewportSize() == Utils.ViewportSizes.MD },
  viewportIsLG: () => { return Utils.viewportSize() == Utils.ViewportSizes.LG },
  viewportIsXL: () => { return Utils.viewportSize() == Utils.ViewportSizes.XL },

  setupViewportResizeEvents: () => {
    Utils.currentViewport = Utils.viewportSize()
    $(window).resize(() => {
      var newViewport = Utils.viewportSize()
      if(newViewport != Utils.currentViewport) {
        Utils.currentViewport = newViewport
        $(window).trigger(Utils.VIEWPORT_RESIZE_EVENT, Utils.currentViewport)
      }
    })
  }
}

$(document).ready(() => {
  Utils.setupViewportResizeEvents()
})
