import { Nav } from "./nav.js";
import { Alert } from "./alert.js";
import { Carousel } from "./carousel.js";
import { ShopifyAddToCartButton } from "./shopify-add-to-cart-button.js";
import { MailingList } from "./mailing-list.js";
import { ProductBullets } from "./product-bullets.js";
import { JigsawPuzzleHints } from "./jigsaw-puzzle-hints.js";
import { Analytics } from "./analytics.js";
import { Reviews } from "./reviews.js";

let App = {
  DOM: {
    window: () => $(window),
    entireBody: () => $('html,body'),
    body: () => $('body'),
    fillHeightSection: () => $(".fill-height"),
    collapseElements: () => $(".collapse"),
    firstSection: () => $('body section:first-child')
  },

  handleFillHeight: () => {
    if(App.DOM.fillHeightSection().length > 0) {
      App.fillHeight()
      $(window).resize(App.fillHeight)
      $(document).on("turbolinks:render", App.fillHeight)
      $(App.DOM.collapseElements()).on("shown.bs.collapse", App.fillHeight)
      onElementHeightChange(document.body, App.fillHeight)
    }
  },

  fillHeight: () => {
    App.DOM.fillHeightSection().css("min-height",0)

    var windowHeight = App.DOM.window().outerHeight()
    var bodyHeight = App.DOM.body().outerHeight()
    var diff = windowHeight - bodyHeight
    if(diff > 0) {
      App.DOM.fillHeightSection().css("min-height", App.DOM.fillHeightSection().outerHeight() + diff)
    }
  },

  handlePageBounceColor: () => {
    App.navColor = Nav.DOM.nav().css("background-color")
    if(Nav.DOM.nav().length == 0) {
      App.navColor = App.DOM.firstSection().css("background-color")
    }
    App.footerColor = Nav.DOM.footer().css("background-color")
    if(Nav.DOM.footer().length == 0) {
      App.navColor = App.DOM.firstSection().css("background-color")
    }
    App.currentBodyColor = App.footerColor
    $(window).scroll(App.checkPageBounceColor)
    App.checkPageBounceColor()
  },

  checkPageBounceColor: () => {
    var top = Nav.windowScrollTop()
    if(top <= 300 && App.currentBodyColor != App.navColor) {
      App.DOM.body().css("background-color", App.navColor)
      App.currentBodyColor = App.navColor
    } else if(top > 300 && App.currentBodyColor != App.footerColor) {
      App.DOM.body().css("background-color", App.footerColor)
      App.currentBodyColor = App.footerColor
    }
  }
}

let onElementHeightChange = (elm, callback) => {
	var lastHeight = elm.clientHeight, newHeight;
  var lastScrollY = window.pageYOffset, newScrollY;
	(function run(){
		newHeight = elm.clientHeight;
		if( lastHeight != newHeight )
			callback();
		lastHeight = newHeight;
    if (elm.onElementHeightChangeTimer)
      clearTimeout(elm.onElementHeightChangeTimer);
		elm.onElementHeightChangeTimer = setTimeout(run, 200);
	})();
}

$(document).ready(() => {
  Nav.setUpNavbar();
  Nav.handleSmoothScrollLinks();
  // Nav.tmpDisableAnimateNavBar();
  App.handleFillHeight();
  App.handlePageBounceColor();
  Carousel.init()
  ShopifyAddToCartButton.init()
  Alert.handle()
  MailingList.handle()
  ProductBullets.handle()
  JigsawPuzzleHints.handle()
  Analytics.handle()
  Reviews.handle()
});
