import { Utils } from "./utils.js";

export let Carousel = {
  DOM: {
    carousels: () => $('.carousel'),
    multislideCarousels: () => $(".carousel.multislide"),
    resizeCarousels: () => $(".carousel.resize-heights"),
    resizeMultislideCarousels: () => $(".carousel.multislide.resize-heights"),
    carouselInner: (carousel) => $(carousel).find(".carousel-inner"),
    carouselItems: (carousel) => $(carousel).find(".carousel-item"),
    activeCarouselItem: (carousel) => Carousel.DOM.carouselItems(carousel).filter(".active"),
    templateCarouselItem: (carousel) => $(carousel).find(".template-carousel-item"),
    carouselItemInners: (carousel) => Carousel.DOM.carouselItems(carousel).find(".carousel-item-inner"),
    carouselPrevButton: (carousel) => $(carousel).find("button.carousel-control-prev").first(),
    carouselNextButton: (carousel) => $(carousel).find("button.carousel-control-next").first(),
    nonWrappingCarousels: () => $(".carousel[data-bs-wrap='false']"),
    productImagesCarousel: () => $("#product-images-carousel")
  },

  init: () => {
    $.each(Carousel.DOM.multislideCarousels(), (i, carousel) => {
      Carousel.makeMultislide(carousel)
    })

    $(window).on(Utils.VIEWPORT_RESIZE_EVENT, Carousel.remakeMultislides)

    $.each(Carousel.DOM.nonWrappingCarousels(), (i, carousel) => {
      Carousel.DOM.carouselPrevButton(carousel).hide()
    })
    Carousel.DOM.nonWrappingCarousels().on('slid.bs.carousel',Carousel.handleNonWrappingCarouselButtons)

    $(window).resize(Carousel.handleProductImagesCarouselHeight)
    Carousel.handleProductImagesCarouselHeight()
  },

  makeMultislide: (carousel) => {
    var templateCarouselItem = Carousel.DOM.carouselItems(carousel).first()
    var inners = templateCarouselItem.children()
    $.each(inners, (i, inner) => {
      var newCarouselItem = $("<div>")
      newCarouselItem.addClass("carousel-item")
      if(i == 0) newCarouselItem.addClass("active")
      newCarouselItem.append($(inner).clone())
      Carousel.DOM.carouselInner(carousel).append(newCarouselItem)
    })

    templateCarouselItem.removeClass("carousel-item")
    templateCarouselItem.addClass("template-carousel-item")
    templateCarouselItem.hide()

    let items = Carousel.DOM.carouselItems(carousel)
    var vs = Utils.viewportSize()
    var minPerSlide = parseInt($(carousel).attr("data-em-num-slides-" + vs)) || items.length

    $.each(items, (i, item) => {
        let next = $(item).next()
        for (var i=1; i<minPerSlide; i++) {
            if (next.length == 0) {
                next = items.first()
            }
            $(item).append(next.clone().children().first())
            next = next.next()
        }
    })

  },

  remakeMultislides: (event, data) => {
    $.each(Carousel.DOM.resizeMultislideCarousels(), (i, carousel) => {
      Carousel.DOM.carouselItems(carousel).remove()
      var templateCarouselItem = Carousel.DOM.templateCarouselItem(carousel)
      templateCarouselItem.removeClass("template-carousel-item")
      templateCarouselItem.addClass("carousel-item")
      templateCarouselItem.show()
      Carousel.makeMultislide(carousel)
    })
  },

  handleNonWrappingCarouselButtons: (event) => {
    var carousel = $(event.target)
    var numSlides = Carousel.DOM.carouselItems(carousel).length
    var currentSlide = event.to

    if(currentSlide == 0) {
      Carousel.DOM.carouselPrevButton(carousel).hide()
      Carousel.DOM.carouselNextButton(carousel).show()
    } else if(currentSlide == numSlides - 1) {
      Carousel.DOM.carouselPrevButton(carousel).show()
      Carousel.DOM.carouselNextButton(carousel).hide()
    } else {
      Carousel.DOM.carouselPrevButton(carousel).show()
      Carousel.DOM.carouselNextButton(carousel).show()
    }
  },

  handleProductImagesCarouselHeight: () => {
    var carousel = Carousel.DOM.productImagesCarousel()

    // reset
    var productImages = carousel.find(".product-image")
    productImages.css("height", productImages.first().css("max-height"))
    var activePI = Carousel.DOM.activeCarouselItem(carousel).find(".product-image")

    var heightHelperImages = carousel.find(".mobile-height-size-helper img")
    var maxHeight = parseInt(activePI.height())
    var maxWidth = parseInt(activePI.width())
    var bestHeight = 0
    $.each(heightHelperImages, (i, img) => {
      var h = $(img).height()
      var w = $(img).width()
      var possibleHeight = maxWidth * h / w
      if(possibleHeight > bestHeight) {
        bestHeight = possibleHeight
      }
    })
    if(bestHeight > maxHeight) {
      bestHeight = maxHeight
    }
    productImages.css("height", bestHeight)
  }
}
