export let Reviews = {
  DOM: {
    ratingsModals: () => $(".ratings-modal"),
    ratingsModal: (el) => $(el).parents(".ratings-modal"),
    writeReviewButtons: () => Reviews.DOM.ratingsModals().find(".ratings-overview button.write-review"),
    writeReviewButton: (el) => Reviews.DOM.ratingsModal(el).find(".ratings-overview button.write-review"),
    ratingsOverview: (el) => $(el).parents(".modal").find(".ratings-overview"),
    starPctWrappers: () => Reviews.DOM.ratingsModals().find(".ratings-overview .star-pct-wrapper"),
    reviewForm: (el) => Reviews.DOM.ratingsModal(el).find(".review-form"),
    reviewForms: () => Reviews.DOM.ratingsModals().find(".review-form"),
    submitReviewButtons: () => Reviews.DOM.reviewForms().find("button.submit"),
    cancelReviewButtons: () => Reviews.DOM.reviewForms().find("button.cancel"),
    reviewStars: () => Reviews.DOM.reviewForms().find(".rate-stars img"),
    submitReviewSpinner: (el) => Reviews.DOM.reviewForm(el).find(".submit-loader"),
    ratingErrorMessage: (el) => Reviews.DOM.ratingsModal(el).find(".rating-error-collapse"),
    ratingSuccessMessage: (el) => Reviews.DOM.ratingsModal(el).find(".rating-success-collapse"),
    reviewTemplate: (el) => Reviews.DOM.ratingsModal(el).find(".review-template"),
    noReviewsNoticeTemplate: (el) => Reviews.DOM.ratingsModal(el).find(".no-reviews-notice-template"),
    reviewList: (el) => Reviews.DOM.ratingsModal(el).find(".review-list"),
    loadMoreButtons: () => Reviews.DOM.ratingsModals().find(".load-more-button button"),
    loadMoreButton: (el) => Reviews.DOM.ratingsModal(el).find(".load-more-button button"),
    loadMoreLoader: (el) => Reviews.DOM.ratingsModal(el).find(".load-more-loader"),
    filterCollapse: (el) => Reviews.DOM.ratingsModal(el).find(".filters-collapse.collapse"),
    filterOptions: () => Reviews.DOM.ratingsModals().find(".filters input"),
    ratingFilter: (el, rating) => Reviews.DOM.ratingsModal(el).find(".filters #filter-rating-" + rating),
    sortFilter: (el, sort) => Reviews.DOM.ratingsModal(el).find(".filters #filter-order-" + sort),
    checkedRatingFilter: (el) => Reviews.DOM.ratingsModal(el).find(".filters input[name='filters[rating]']:checked"),
    checkedSortFilter: (el) => Reviews.DOM.ratingsModal(el).find(".filters input[name='filters[order]']:checked")

  },

  handle: () => {
    Reviews.DOM.reviewForms().hide()
    Reviews.DOM.writeReviewButtons().on("click touchstart", Reviews.writeReviewButtonClicked)
    Reviews.DOM.submitReviewButtons().on("click touchstart", Reviews.submitReviewButtonClicked)
    Reviews.DOM.cancelReviewButtons().on("click touchstart", Reviews.cancelReviewButtonClicked)
    Reviews.DOM.reviewStars().on("click touchstart", Reviews.reviewStarHovered)
    Reviews.DOM.ratingsModals().on("hidden.bs.modal", Reviews.ratingsModalClosed)
    Reviews.DOM.loadMoreButtons().on("click touchstart", Reviews.loadMoreButtonClicked)
    Reviews.DOM.ratingsModals().on("show.bs.modal", Reviews.ratingsModalShown)
    Reviews.DOM.filterOptions().change(Reviews.filterOptionSelected)
    Reviews.enableStarPctWrapperClicks()
    Reviews.checkLinkedToWriteReview()
  },

  checkLinkedToWriteReview: () => {
    if(window.location.hash == "#review") {
      if(Reviews.DOM.ratingsModals().length > 0) {
        Reviews.DOM.ratingsModals().modal("show")
        setTimeout(() => {
          Reviews.DOM.writeReviewButtons().click()
        }, 2000)
      }
    }
  },

  enableStarPctWrapperClicks: () => {
    Reviews.DOM.starPctWrappers().on("click touchstart", Reviews.starPctWrapperClicked)
  },

  disableStarPctWrapperClicks: () => {
    Reviews.DOM.starPctWrappers().off("click touchstart")
  },

  writeReviewButtonClicked: (e) => {
    Reviews.DOM.ratingsOverview(e.target).hide()
    Reviews.DOM.reviewForm(e.target).show()
  },

  reviewStarHovered: (e) => {
    var star = $(e.target)
    var numStar = star.attr("data-em-rating")
    star.parent().attr("data-em-rating", numStar)
  },

  submitReviewButtonClicked: (e) => {
    var spinner = Reviews.DOM.submitReviewSpinner(e.target)
    spinner.show()

    var buttons = $(e.target).parent()
    buttons.hide()

    var form = Reviews.DOM.reviewForm(e.target)
    form.find("input, textarea, button").prop( "disabled", true );

    var productId = form.find("input[name='productId']").val()
    var rating = form.find(".rate-stars").attr("data-em-rating")
    var name = form.find("input[name='name']").val()
    var email = form.find("input[name='email']").val()
    var title = form.find("input[name='title']").val()
    var content = form.find("textarea[name='content']").val()

    setTimeout(() => {
      Reviews.createReview(productId, rating, name, email, title, content, (success, msg) => {
        spinner.hide()
        form.find("input, textarea, button").prop( "disabled", false);
        if(!success) {
          var errorMessageBanner = Reviews.DOM.ratingErrorMessage(e.target)
          errorMessageBanner.find("p").html(msg)
          errorMessageBanner.collapse("show")
          buttons.show()
        } else {
          var successMessageBanner = Reviews.DOM.ratingSuccessMessage(e.target)
          successMessageBanner.collapse("show")
          Reviews.cancelReviewButtonClicked(e)
          Reviews.DOM.writeReviewButton(e.target).hide()
        }
      })
    }, 500)
  },

  ratingsModalClosed: (e) => {
    var target = $(e.target).find(".modal-body")
    Reviews.cancelReviewButtonClicked({target: target})
    Reviews.resetReviewList({target: target})
    Reviews.DOM.ratingFilter(target, "all").prop("checked",true)
    Reviews.DOM.sortFilter(target, "newest-to-oldest").prop("checked",true)
    Reviews.DOM.filterCollapse(target).collapse("hide")
  },

  ratingsModalShown: (e) => {
    var target = $(e.target).find(".modal-body")
    Reviews.loadMoreButtonClicked({target: Reviews.DOM.loadMoreButton(target)})
  },

  cancelReviewButtonClicked: (e) => {
    Reviews.DOM.reviewForm(e.target).hide()
    Reviews.DOM.ratingsOverview(e.target).show()
    Reviews.DOM.ratingSuccessMessage(e.target).removeClass("show")
    Reviews.DOM.ratingErrorMessage(e.target).removeClass("show")
  },

  starPctWrapperClicked: (e) => {
    var pctWrapper = $(e.target)
    if(!pctWrapper.is("[data-em-rating]")) {
      pctWrapper = pctWrapper.parents(".star-pct-wrapper")
    }
    var rating = pctWrapper.attr("data-em-rating")
    Reviews.DOM.ratingFilter(e.target, rating).prop("checked",true)
    Reviews.filterOptionSelected(e)
    pctWrapper.blur()
  },

  filterOptionSelected: (e) => {
    Reviews.resetReviewList(e)
    Reviews.loadMoreButtonClicked({target: Reviews.DOM.loadMoreButton(e.target)})
  },

  resetReviewList: (e) => {
    Reviews.DOM.reviewList(e.target).html("")
    Reviews.DOM.loadMoreButton(e.target).attr("data-em-next-page", 1)
  },

  loadMoreButtonClicked: (e) => {
    var button = $(e.target)
    button.parent().hide()

    var loader = Reviews.DOM.loadMoreLoader(e.target)
    loader.show()

    Reviews.DOM.filterOptions().attr("disabled","disabled")
    Reviews.disableStarPctWrapperClicks()

    var nextPage = button.attr("data-em-next-page")
    var productId = button.attr("data-em-product-id")
    var ratingFilter = Reviews.DOM.checkedRatingFilter(e.target).val()
    var sortOrder = Reviews.DOM.checkedSortFilter(e.target).val()

    setTimeout(() => {
      Reviews.fetchReviews(productId, ratingFilter, sortOrder, nextPage, (success, reviews, nextPage) => {
        loader.hide()
        Reviews.DOM.filterOptions().removeAttr("disabled")
        Reviews.enableStarPctWrapperClicks()

        if(!success) {
          Reviews.DOM.reviewList(button).append(Reviews.DOM.noReviewsNoticeTemplate(button).html())
        } else {
          if(reviews.length > 0) {
            $.each(reviews, (i, review) => {
              var templateReview = $(Reviews.DOM.reviewTemplate(button).html())
              templateReview.find(".reviewer-name").html(review.name)
              templateReview.find(".review-date").html(review.formatted_date)
              templateReview.find(".reviewer-stars").attr("data-em-rating", review.rating)
              templateReview.find(".review-title").html(review.title)
              templateReview.find(".review-body").html(review.content)
              if(!review.is_verified) {
                templateReview.find(".verified-buyer").remove()
              }
              Reviews.DOM.reviewList(button).append(templateReview)
            })
            if(nextPage) {
              button.attr("data-em-next-page",nextPage)
              button.parent().show()
            }
          } else {
            Reviews.DOM.reviewList(button).append(Reviews.DOM.noReviewsNoticeTemplate(button).html())
          }
        }
      })
    }, 500)
  },

  fetchReviews: (productId, rating, order, page, callback) => {
    var url = "/api/products/" + productId + "/reviews.json"

    var sendData = {
      filters: {
        rating: rating,
        order: order
      },
      page: page
    }

    $.ajax({
      type: "GET",
      url: url,
      data: sendData,
      success: function(data, textStatus, jqXHR) {
        callback(true, data.reviews, data.next_page)
      },
      error: function(jqXHR, textStatus, errorThrown) {
        callback(false)
      }
    })
  },

  createReview: (productId, rating, name, email, title, content, callback) => {
    var url = "/api/products/" + productId + "/reviews.json"

    var sendData = {
      review: {
        rating: rating,
        name: name,
        email: email,
        title: title,
        content: content
      }
    }

    $.ajax({
      type: "POST",
      url: url,
      data: sendData,
      success: function(data, textStatus, jqXHR) {
        callback(true, {})
      },
      error: function(jqXHR, textStatus, errorThrown) {
        callback(false, jqXHR.responseJSON.error)
      }
    })
  }
}
