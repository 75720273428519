import { Analytics } from "./analytics.js";

export let ShopifyAddToCartButton = {
  DOM: {
    addToCartButtons: () => $(".shopify-add-to-cart-button")
  },

  init: () => {
    $.each(ShopifyAddToCartButton.DOM.addToCartButtons(), (i, addToCartButton) => {
      ShopifyAddToCartButton.initButton(
        $(addToCartButton)[0],
        $(addToCartButton).attr("data-em-product-id"),
        $(addToCartButton).attr("data-em-theme"),
        $(addToCartButton).attr("data-em-alignment"),
        $(addToCartButton).attr("data-em-is-preorder") == "true"
      )
    })
  },

  initButton: (node, shopifyProductId, theme, alignment, isPreorder) => {
    (function () {
      var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
      if (window.ShopifyBuy) {
        if (window.ShopifyBuy.UI) {
          ShopifyBuyInit();
        } else {
          loadScript();
        }
      } else {
        loadScript();
      }
      function loadScript() {
        var script = document.createElement('script');
        script.async = true;
        script.src = scriptURL;
        (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
        script.onload = ShopifyBuyInit;
      }
      function ShopifyBuyInit() {
        var client = ShopifyBuy.buildClient({
          domain: 'byevermade.myshopify.com',
          storefrontAccessToken: 'ab15be67f60c45add2af0c58fc25d54c',
        });
        ShopifyBuy.UI.onReady(client).then(function (ui) {
          ui.createComponent('product', {
            id: shopifyProductId,
            node: node,
            moneyFormat: '%24%7B%7Bamount%7D%7D',
            options: {
      "product": {
        "iframe": true,
        "events": {
          "addVariantToCart": Analytics.addToCartButtonClicked,
          afterRender: function(comp) {
            var button = $(comp.view.wrapper).find("button")
            if(isPreorder) {
              button.html("PREORDER* - $" + comp.selectedVariant.price.amount)
            } else if(comp.selectedVariant.available) {
              button.html("ADD TO CART - $" + comp.selectedVariant.price.amount)
            } else {
              button.html("TEMPORARILY SOLD OUT")
            }
          }
        },
        "styles": {
          "product": {
            "display": alignment == "left" ? "inline-block" : "block",
            "width": alignment == "left" ? "auto" : "100%",
            "@media (min-width: 601px)": {
              "margin-left": "20px",
              "margin-bottom": "50px"
            }
          },
          "title": {
            "font-family": "Lora, serif",
            "color": "#002c50"
          },
          "button": {
            "font-family": '"Poppins", sans-serif',
            "font-weight": "600",
            "letter-spacing": "0.05em",
            "text-transform": "uppercase",
            "font-size": "14px",
            "padding-top": "10px",
            "padding-bottom": "10px",
            "color": "#333132",
            "display": alignment == "left" ? "inline-block" : "block",
            ":hover": {
              "color": "#333132",
              "background-color": "#e6e6e6"
            },
            "background-color": "#ffffff",
            ":focus": {
              "background-color": "#ffffff",
              "outline": "2px dotted !important",
              "outline-offset": "-3px !important",
              "outline-color": "333132 !important"
            },
            "border-radius": "40px",
            "padding-left": "35px",
            "padding-right": "35px",
            "margin-top": "-20px !important",
          },
          "quantityInput": {
            "font-size": "14px",
            "padding-top": "15px",
            "padding-bottom": "15px"
          },
          "price": {
            "font-family": "Droid Sans, sans-serif",
            "font-weight": "bold",
            "color": "#aec1d5"
          },
          "compareAt": {
            "font-family": "Droid Sans, sans-serif",
            "font-weight": "bold",
            "color": "#aec1d5"
          },
          "unitPrice": {
            "font-family": "Droid Sans, sans-serif",
            "font-weight": "bold",
            "color": "#aec1d5"
          }
        },
        "contents": {
          "img": false,
          "title": false,
          "price": false
        },
        "googleFonts": [
          "Lora",
          "Droid Sans"
        ]
      },
      "productSet": {
        "styles": {
          "products": {
            "@media (min-width: 601px)": {
              "margin-left": "-20px"
            }
          }
        }
      },
      "modalProduct": {
        "contents": {
          "img": false,
          "imgWithCarousel": true,
          "button": false,
          "buttonWithQuantity": true
        },
        "styles": {
          "product": {
            "@media (min-width: 601px)": {
              "max-width": "100%",
              "margin-left": "0px",
              "margin-bottom": "0px"
            }
          },
          "button": {
            "font-weight": "bold",
            "font-size": "14px",
            "padding-top": "15px",
            "padding-bottom": "15px",
            "color": "#333132",
            ":hover": {
              "color": "#333132",
              "background-color": "#e6e6e6"
            },
            "background-color": "#ffffff",
            ":focus": {
              "background-color": "#e6e6e6"
            },
            "border-radius": "40px",
            "padding-left": "100px",
            "padding-right": "100px"
          },
          "quantityInput": {
            "font-size": "14px",
            "padding-top": "15px",
            "padding-bottom": "15px"
          },
          "title": {
            "font-family": "Helvetica Neue, sans-serif",
            "font-weight": "bold",
            "font-size": "26px",
            "color": "#4c4c4c"
          },
          "price": {
            "font-family": "Helvetica Neue, sans-serif",
            "font-weight": "normal",
            "font-size": "18px",
            "color": "#4c4c4c"
          },
          "compareAt": {
            "font-family": "Helvetica Neue, sans-serif",
            "font-weight": "normal",
            "font-size": "15.299999999999999px",
            "color": "#4c4c4c"
          },
          "unitPrice": {
            "font-family": "Helvetica Neue, sans-serif",
            "font-weight": "normal",
            "font-size": "15.299999999999999px",
            "color": "#4c4c4c"
          }
        },
        "text": {
          "button": "Add to cart"
        }
      },
      "option": {},
      "cart": {
        "events": {
          "openCheckout": Analytics.checkoutOpened
        },
        "styles": {
          "button": {
            "font-family": '"Poppins", sans-serif',
            "text-transform": "uppercase",
            "letter-spacing": "0.05em",
            "font-weight": "bold",
            "font-size": "18px",
            "padding-top": "15px",
            "padding-bottom": "15px",
            "color": "white",
            ":hover": {
              "color": "white",
              "background-color": "#F15625"
            },
            "background-color": "#F15625",
            ":focus": {
              "background-color": "#F15625"
            },
            "border-radius": "40px"
          },
          "cart": {
            "background-color": "white",
          },
          "title": {
            "font-family": '"Poppins", sans-serif',
            "color": "#333132"
          },
          "header": {
            "font-family": '"Poppins", sans-serif',
            "color": "#333132"
          },
          "lineItems": {
            "font-size": "30px",
            "font-family": '"Poppins", sans-serif',
            "color": "#333132"
          },
          "subtotalText": {
            "font-family": '"Poppins", sans-serif',
            "color": "#333132"
          },
          "subtotal": {
            "font-family": '"Poppins", sans-serif',
            "color": "#333132"
          },
          "notice": {
            "font-family": '"Poppins", sans-serif',
            "color": "#333132"
          },
          "currency": {
            "font-family": '"Poppins", sans-serif',
            "color": "#333132"
          },
          "close": {
            "color": "#333132",
            "outline": "none",
            ":hover": {
              "color": "#333132"
            }
          },
          "empty": {
            "font-family": '"Poppins", sans-serif',
            "color": "#333132",
            "font-size": "21px"
          },
          "noteDescription": {
            "font-family": '"Poppins", sans-serif',
            "color": "#333132"
          },
          "discountText": {
            "font-family": '"Poppins", sans-serif',
            "color": "#333132"
          },
          "discountIcon": {
            "fill": "#333132"
          },
          "discountAmount": {
            "font-family": '"Poppins", sans-serif',
            "color": "#333132"
          }
        },
        "text": {
          "total": "Subtotal",
          "empty": "Nothing here yet! Keep browsing for your favorite games!",
          "button": "Checkout →"
        },
        "popup": false
      },
      "toggle": {
        "styles": {
          "toggle": {
            "font-weight": "bold",
            "background-color": "white",
            "ouline-color": "white !important",
            ":hover": {
              "background-color": "#e6e6e6"
            },
            ":focus": {
              "background-color": "#e6e6e6"
            }
          },
          "count": {
            "font-size": "16px",
            "font-family": '"Poppins", sans-serif',
            "color": "#333132",
            ":hover": {
              "color": "#333132"
            }
          },
          "iconPath": {
            "fill": "#333132"
          }
        }
      },
      "lineItem": {
        "styles": {
          "variantTitle": {
            "font-family": '"Poppins", sans-serif',
            "color": "#333132"
          },
          "title": {
            "font-family": '"Poppins", sans-serif',
            "color": "#333132"
          },
          "price": {
            "font-family": '"Poppins", sans-serif',
            "color": "#333132"
          },
          "fullPrice": {
            "font-family": '"Poppins", sans-serif',
            "color": "#333132"
          },
          "discount": {
            "font-family": '"Poppins", sans-serif',
            "color": "#333132"
          },
          "discountIcon": {
            "font-family": '"Poppins", sans-serif',
            "fill": "#333132"
          },
          "quantity": {
            "font-family": '"Poppins", sans-serif',
            "color": "#333132"
          },
          "quantityIncrement": {
            "font-family": '"Poppins", sans-serif',
            "color": "#333132",
            "border-color": "#333132"
          },
          "quantityDecrement": {
            "font-family": '"Poppins", sans-serif',
            "color": "#333132",
            "border-color": "#333132"
          },
          "quantityInput": {
            "font-family": '"Poppins", sans-serif',
            "color": "#333132",
            "border-color": "#333132"
          }
        }
      }
    },
          });
        });
      }
    })();
  }
}
